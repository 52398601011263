import axios from "axios";

let success = function (params) {
    const updateStatus = function(){
        axios.post(process.env.API_URL + 'api/order/check', {
            uuid: params.uuid
        })
            .then(function (response) {
                response = response.data;

                if(response.status){
                    const uuid = response.data.uuid;
                    if (uuid) {
                        window.location.href = response.data.redirectBaseUrl + 'onetouch/' + uuid;
                    } else {
                        setTimeout(function(){
                            updateStatus();

                        }, 1000);
                    }
                }

            })
            .catch(function (error) {
                window.location.href = '/';
            });
    };

    updateStatus();

};

export default success;