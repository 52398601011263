import utils from "./utils"
import _ from "lodash";
import Accordion from 'accordion-js';
import 'accordion-js/dist/accordion.min.css';
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import axios from "axios";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let main = function (params) {

    // console.log('LOADING MAIN PAGE');
    //
    // const $page = $('.page-main');
    //
    // const isMobile = utils.isMobileAndTablet();
    //
    // console.log(isMobile);
    //
    // console.log(process.env.SOURCE)

    const $page = $('.page-main');
    const $emailStatus = $page.find('.block.order .statusEmail');
    const $buttonBuy = $page.find('.button.buy');
    const $checkAlien = $('#alien');
    const $checkGift = $('#gift');
    const $giftGroup = $('.gift-wrapper');
    const $input = $('#order-email');
    const $giftEmail = $('#order-gift-email');
    const $giftName = $('#order-gift-name');
    const $giftMessage = $('#order-gift-message');


    const $promocodeInput = $('#order-promocode');
    const $promocodeStatus = $('.statusPromocode');
    const $promocodeTrigger = $promocodeInput.parent().find('.action');

    const $productName = $('.line-name .name');
    const $productPrice = $('.line-name .price');
    const $oldPrice = $('.oldprice');
    const $totalPrice = $('.total .price');
    const $linePromo = $('.line-promo');

    let activeProduct = 1;
    let activePromocode = '';

    const $banner = $('.banner');

    const renderBannerTimer = function(){
        let promotionDateTo = '2024-11-16T23:59';
        let lapsed = Math.abs(Date.now() - Date.parse(promotionDateTo));
        lapsed = utils.periodFromSeconds(lapsed / 1000);

        $banner.find('.days').text(lapsed[0]).parent().find('span').text(utils.countWords(lapsed[0], ['день', 'дня', 'дней']));
        $banner.find('.hours').text(lapsed[1]).parent().find('span').text(utils.countWords(lapsed[1], ['час', 'часа', 'часов']));
        $banner.find('.minutes').text(lapsed[2]).parent().find('span').text(utils.countWords(lapsed[2], ['минута', 'минуты', 'минут']));
        $banner.find('.seconds').text(lapsed[3]).parent().find('span').text(utils.countWords(lapsed[3], ['секунда', 'секунды', 'секунд']));
        setTimeout(renderBannerTimer, 1000)
    };
    renderBannerTimer();

    $page.find('.legal_year').html(new Date().getFullYear());

    const sliderPlans = new Swiper('.plans-slider', {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 3,
        spaceBetween: 40,
        breakpoints: {
            340: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 'auto',
                spaceBetween: 20,
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },

        pagination: {
            el: '.plans-pagination',
        },
    });

    const sliderReviews = new Swiper('.reviews-slider', {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 40,
        breakpoints: {
            340: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 'auto',
                spaceBetween: 20,
            },
            1440: {
                slidesPerView: 'auto',
                spaceBetween: 40,
            },
        },
        pagination: {
            el: '.reviews-pagination',
        },
        navigation: {
            nextEl: '.button.round.right',
            prevEl: '.button.round.left',
        }
    });


    new Accordion('.table', {
        duration: 400,
        elementClass: 'row',
        triggerClass: 'question',
        panelClass: 'answer',
        activeClass: 'active',
        // onOpen: function(currentElement) {
        //     console.log(currentElement);
        // }
    });

    const format = function (number) {
        return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ₽';
    }


    const closeModal = function (modal) {
        const $modal = $('.block.' + modal);
        $modal.removeClass('active');
        enableBodyScroll($modal[0]);
    };
    const activateCloseListeners = function (modal) {
        const $modalElem = $page.find('.block.' + modal);
        $modalElem.on('click', function (e) {
            if (e.target !== e.currentTarget) return;
            // console.log('clicked outside modal frame');
            closeModal(modal);
        });
        $modalElem.find('.close').on('click', function (e) {
            // console.log('clicked close button');
            closeModal(modal);
        });
    };

    activateCloseListeners('order');


    const resetModal = function (){

        console.log(activeProduct);
        console.log(params[activeProduct]);
        activePromocode = '';
        $oldPrice.removeClass('active');
        $linePromo.removeClass('active');
        $promocodeInput.val('');
        $promocodeInput.prop('disabled', false);
        $promocodeTrigger.removeClass('filled').removeClass('active');
        $promocodeStatus.removeClass('active').removeClass('success');
        $totalPrice.text(format(params[activeProduct].price));
        $productName.text(params[activeProduct].name);
        $productPrice.text(format(params[activeProduct].price));
        $oldPrice.text(format(params[activeProduct].price));

    };


    $page.find('.button.go').on('click', function () {
        activeProduct = $(this).attr('id').split('-')[1];
        const $modal = $page.find('.block.order');
        disableBodyScroll($modal[0]);
        resetModal();
        $modal.addClass('active');
    });

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validate = () => {
        if($checkGift.is(':checked')) {
            if (validateEmail($input.val()) && validateEmail($giftEmail.val()) && $giftName.val() !== '') {
                $input.removeClass('invalidated');
                $buttonBuy.removeClass('disabled');
            } else {
                $input.addClass('invalidated');
                $buttonBuy.addClass('disabled');
            }
        }else{
            if (validateEmail($input.val())) {
                $input.removeClass('invalidated');
                $buttonBuy.removeClass('disabled');
            } else {
                $input.addClass('invalidated');
                $buttonBuy.addClass('disabled');
            }
        }
        return false;
    }

    $input.on('input', validate);
    $giftEmail.on('input', validate);
    $giftName.on('input', validate);
    $checkGift.on('change', function () {
        if ($(this).is(':checked')) {
            $giftGroup.addClass('active');
        } else {
            $giftGroup.removeClass('active');
        }
        validate();
    });


    $promocodeInput.on('input', function(){
        if($(this).val() === ''){
            $promocodeStatus.removeClass('active')
        }
    });
    $promocodeInput.on('focus', function(){
        $promocodeTrigger.addClass('active');
    }).on('blur', function(){
        if($promocodeInput.val() === '') {
            console.log('blur');
            $promocodeTrigger.removeClass('active');
        }
    });

    $promocodeTrigger.on('click', function(){
        if(!$(this).hasClass('filled')){
            if($promocodeInput.val() !== ''){
                console.log('proceed recalculate');
                recalculate($promocodeInput.val());
            }
        }else{
            console.log('proceed remove');
            resetModal();
        }
    });


    let isPromoActive = true;
    const recalculate = function(promocode){
        if(isPromoActive) {
            isPromoActive = false;

            $promocodeStatus.removeClass('active').removeClass('success');

            axios.post(process.env.API_URL + 'api/price/recalculate', {
                product: activeProduct,
                promocode: promocode
            })
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        // const data = {
                        //     price: 10990,
                        //     discount: -1099.00,
                        //     total: 9891.00,
                        //     promoStatus: true,
                        //     promocode: 'PODAROK2024'
                        // }
                        const data = response.data;

                        if (data.promoStatus) {
                            $promocodeInput.prop('disabled', true);
                            $oldPrice.addClass('active');
                            $linePromo.find('.discount').text(format(data.discount));
                            $linePromo.addClass('active');
                            $promocodeTrigger.addClass('filled');
                            $promocodeStatus.text('Промокод активирован').addClass('success active');
                            $totalPrice.text(format(data.total));
                            activePromocode = data.promocode;
                        } else {
                            $promocodeStatus.text('Промокод не найден').addClass('active');
                        }
                    }else{
                        $promocodeStatus.text('Промокод не найден').addClass('active');
                    }
                })
                .then(function(){
                    isPromoActive = true;
                })
                .catch(function (error) {
                    $promocodeStatus.text('Нет соединения с сервером').addClass('active');
                    console.log(error);
                });
        }

    };


    $buttonBuy.on('click', function(){
        if(!$(this).hasClass('disabled')){
            purchase($input.val(), $giftEmail.val(), $giftName.val(), $giftMessage.val());
        }
    });


    let isButtonActive = true;
    let emailSuggestionShown = false;
    const purchase = function (email, giftEmail, giftName, giftMessage) {
        if (isButtonActive) {
            isButtonActive = false;

            $emailStatus.removeClass('active').html('');
            $buttonBuy.addClass('disabled');

            const referer = window.location.search.substring(1);

            const data = {
                product: activeProduct,
                promocode: activePromocode,
                user: {
                    email: email,
                    phone: '-',
                    emailSuggestionShown: emailSuggestionShown
                },
                referral: referer,
                isAlien: $checkAlien.is(':checked'),
                isGift: $checkGift.is(':checked'),
                recipient: {
                    email: giftEmail,
                    name: giftName,
                    message: giftMessage
                }
            };

            // console.log(data);

            axios.post(process.env.API_URL + 'api/payment/create', data)
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        window.location.href = response.data.paymentURL;
                    }else{
                        // console.log(response.message);
                        let errorMessage = response.message;
                        if(errorMessage === 'typo'){
                            errorMessage = 'Возможно, вы имели в виду <span class="highlight small">'+response.data.suggestion+'</span>';
                            emailSuggestionShown = true;
                        }
                        $emailStatus.html(errorMessage).addClass('active');
                        $emailStatus.find('.highlight').on('click', function(){
                            $input.val($(this).text());
                            $emailStatus.removeClass('active').html('');
                        });
                        isButtonActive = true;
                        $buttonBuy.removeClass('disabled');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    isButtonActive = true;
                    $buttonBuy.removeClass('disabled');
                });

        }
    };


    // HOW IT WORKS

    const mediaQuery = window.matchMedia("(min-width: 768px)");
    let isAnimationEnabled = false;

    const container = document.querySelector('.block.how');
    const paragraphs = container.querySelectorAll('.paragraph');
    const laptopContents = container.querySelectorAll('.laptop-content');

    function enableAnimations() {
        console.log('enable animation');
        paragraphs.forEach((paragraph, index) => {
            paragraph.style.opacity = '1';

            gsap.fromTo(paragraph, { opacity: 0 }, {
                opacity: 1,
                scrollTrigger: {
                    trigger: paragraph,
                    start: "top center",
                    end: "bottom center",
                    toggleActions: "play none none reverse"
                }
            });

            ScrollTrigger.create({
                trigger: paragraph,
                start: "top 50%",
                end: "bottom 50%",
                onEnter: () => {
                    laptopContents.forEach((img, i) => {
                        gsap.to(img, { opacity: i === index ? 1 : 0, duration: 0.2 });
                    });
                },
                onLeaveBack: () => {
                    laptopContents.forEach((img, i) => {
                        gsap.to(img, { opacity: i === index - 1 ? 1 : 0, duration: 0.2 });
                    });
                }
            });
        });

        isAnimationEnabled = true;
    }

    function disableAnimations() {
        console.log('disable animation');
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        const paragraphs = document.querySelectorAll('.paragraph');
        paragraphs.forEach(paragraph => paragraph.style.opacity = '1');
        isAnimationEnabled = false;
    }

    function handleMediaChange(e) {
        console.log('handle media change');
        if (e.matches && !isAnimationEnabled) {
            console.log('case 1');
            enableAnimations();
        } else if (!e.matches && isAnimationEnabled) {
            console.log('case 2');
            disableAnimations();
        }
    }

    if (mediaQuery.matches) {
        enableAnimations();
    } else {
        disableAnimations();
    }

    mediaQuery.addEventListener("change", () => {
        console.log('change event');
        handleMediaChange(mediaQuery)
    });

    window.addEventListener("resize", () => {
        console.log('resize event');
        handleMediaChange(mediaQuery)
    });

    setTimeout(()=>window.dispatchEvent(new Event('resize')), 1000);

};

export default main;